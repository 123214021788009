import BaseCard from 'components/BaseCard'
import Button from 'components/Button'

import './CardsCard.scss'

const CardsCard = ({ id, title, description, image, onClick }) => {
  return (
    <BaseCard className="cards-card" id={id}>
      <img src={image} alt="Cartão RVBR" className="cards-card__image" />

      <h2 className="cards-card__title">{title}</h2>
      <p className="cards-card__description">{description}</p>

      <Button className="cards-card__button" onClick={onClick}>
        Quero esse
      </Button>
    </BaseCard>
  )
}

export default CardsCard
